<template>
  <div class="page_container">
    <div class="common_layout">
      <el-container style="height: 100%">
        <el-header height="80px" style="padding: 0">
          <v-header></v-header>
        </el-header>
        <el-container>
          <el-aside>
            <v-aside></v-aside>
          </el-aside>
          <div class="content_box" :class="{ 'content-collapse': aside.collapse }">
            <div class="content">
              <el-main>
                <router-view v-slot="{ Component }">
                  <transition
                    name="move"
                    mode="out-in"
                    :duration="{ enter: 200, leave: 200 }"
                  >
                    <keep-alive :include="tags.nameList">
                      <component :is="Component" :key="route.fullPath" />
                    </keep-alive>
                  </transition>
                </router-view>
              </el-main>
            </div>
          </div>
        </el-container>
      </el-container>
    </div>
  </div>
</template>
<script setup lang="ts">
import { inject, ref } from "vue";
import { useRoute } from "vue-router";
import { useIndexStore } from "@/stores/index";
import { useAsideStore } from "@/stores/aside";
import { useTagsStore } from "@/stores/tags";
import { storeToRefs } from "pinia";
import { ElMessage } from "element-plus";
import vHeader from "@/components/header.vue";
import vAside from "@/components/aside.vue";

const aside = useAsideStore();
const tags = useTagsStore();
const route = useRoute();
const { firstList } = storeToRefs(useIndexStore());
const timesInfo = ref();
const API = inject("API") as any;
console.log("API", API);
function getTimeInfo() {
  const params = {
    pageNo: 1,
    pageSize: 10,
  };
  API.getSelectTime(params).then((res: any) => {
    if (res.data.code === 0) {
      ElMessage.success("获取数据成功！");
      timesInfo.value = res.data.data.fullDateDTOList;
      console.log("timesInfo", timesInfo.value);
    }
  });
}
</script>

<style lang="scss" scoped>
.common_layout {
  height: 100%;
  width: 100%;
}
.el-aside {
  width: 250px;
}
</style>
