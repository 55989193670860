import axios, { type AxiosInstance, AxiosError, type AxiosResponse, type InternalAxiosRequestConfig } from 'axios';
import { useEnv } from '@/hooks/useEnv';
import { ElMessage } from 'element-plus';

const { VITE_BASE_API } = useEnv()
// 创建axios实例
const service: AxiosInstance = axios.create({
    baseURL: VITE_BASE_API,
    timeout: 9999999
});
// 请求拦截器
service.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        if (config.headers) {
            config.headers["Authorization"] = "Bearer " + localStorage.getItem("token") || "";
            config.headers["tenant-id"] = 1;
        }
        return config;
    },
    (error: AxiosError) => {
        ElMessage.error(error.message);
        return Promise.reject();
    }
);

// 响应拦截器
service.interceptors.response.use(
    (response: AxiosResponse<any>) => {
        if (response.status === 200) {
            return response;
        } else {
            return Promise.reject();
        }
    },
    (error: AxiosError) => {
        ElMessage.error(error.message);
        return Promise.reject();
    }
);

export default service;
