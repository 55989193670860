import { defineStore } from "pinia"

interface ListItem {
    title: string,
    desc: string,
    link: string
}
export const useIndexStore = defineStore("index", {
    state: () => {
        return {
            firstList: <ListItem[]>[
                {
                    title: "Pinia",
                    desc: "A Tailwind CSS Starter Template",
                    link: "https://pinia.esm.dev/"
                },
                {
                    title: "Vue 3",
                    desc: "A JavaScript Framework",
                    link: "https://v3.vuejs.org/"
                }
            ]
        }
    },
    actions: {

    }
})