<template>
  <div class="header_container">
    <!-- 折叠按钮 -->
    <div class="collapse_btn">
      <div @click="aside.handleCollapse">
        <i-ep-expand v-if="aside.collapse"></i-ep-expand>
        <i-ep-fold v-else></i-ep-fold>
      </div>
    </div>
    <div>
      <p>早上好！管理员</p>
    </div>
    <div></div>
  </div>
</template>
<script setup lang="ts">
import { useAsideStore } from "@/stores/aside";
const aside = useAsideStore();
</script>
<style lang="scss" scoped>
.header_container {
  color: #fff;
  font-size: 22px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #83a2ff;
  display: flex;
  flex-direction: row;
}
.collapse_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;

  div {
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
}
</style>
