import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router';
import Colada, { PiniaColadaPlugin } from "colada-plugin";
import './style.css'
import App from './App.vue'
import API from "@/api/index.ts"
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'element-plus/dist/index.css' // 引入elementPlus样式
import "./assets/main.scss"

const app = createApp(App)
const pinia = createPinia()


// 注册elementplus图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.provide('API', API)
app.use(pinia)
pinia.use(PiniaColadaPlugin)
app.use(Colada)
app.use(router)

app.mount('#app')

