import { defineStore } from 'pinia'

export const useAsideStore = defineStore('aside', {
    state: () => {
        return {
            collapse: false
        }
    },
    actions: {
        // 收起侧边栏
        handleCollapse() {
            this.collapse = !this.collapse
        }
    }
})