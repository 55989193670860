// 管理所有的请求接口
import request from "@/utils/request"

const API = {
    // 获取时间接口
    getSelectTime: (params: any) => request.get("/endpoint-api/change/venue-reservation/queryReservationTimePeriod", params),
    // 富文本上传图片
    uploadImg: (params: any) => request.post("/endpoint-api/change/ossupload/uploadFileCreateObject",
        { uploadFile: params.uploadFile, clientDir: params.clientDir },
        {
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
            }
        }),
    uploadUnityImg: (params: any) => request.post("/endpoint-api/change/ossupload/unityUploadFile",
        {
            uploadFile: params.uploadFile,
            fileName: params.fileName,
            projectFolderName: params.projectFolderName,
            // byteArray: params.byteArray,
            // base64Img: params.base64Img
        },
        {
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
            }
        })
}

export default API