import { createRouter, createWebHashHistory, type RouteRecordRaw } from 'vue-router';
import Home from "@/views/home.vue"
const routes: RouteRecordRaw[] = [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login.vue")
    },
    {
        path: "/",
        redirect: "/login"
    },
    {
        path: "/home",
        name: "home",
        component: Home,
        children: [
            {
                path: "/articleTable",
                name: "articleTable",
                component: () => import("@/views/contentManagement/articleTable/index.vue")
            },
            {
                path: "/item",
                name: "item",
                component: () => import("@/views/textGroup1/item.vue")
            },
            {
                path: "/item2",
                name: "item2",
                component: () => import("@/views/textGroup1/item2.vue")
            },
            {
                path: "/item3",
                name: "item3",
                component: () => import("@/views/textGroup1/item3.vue")
            }
        ]
    }

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})


export default router
