<template>
  <div class="sidebar">
    <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="sidebar.collapse" background-color="#B4BDFF"
      text-color="#fff" active-color="#000" unique-opened router>
      <template v-for="item in routerArray">
        <template v-if="item.subs">
          <el-sub-menu :index="item.index" :key="item.index">
            <template #title>
              <el-icon>
                <component :is="item.icon"></component>
              </el-icon>
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-sub-menu v-if="subItem.subs" :index="subItem.index" :key="subItem.index">
                <template #title>{{ subItem.title }}</template>
                <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="i" :index="threeItem.index">
                  {{ threeItem.title }}
                </el-menu-item>
              </el-sub-menu>
              <el-menu-item v-else :index="subItem.index">
                {{ subItem.title }}
              </el-menu-item>
            </template>
          </el-sub-menu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <el-icon>
              <component :is="item.icon"></component>
            </el-icon>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useAsideStore } from "@/stores/aside";
import { useRoute } from "vue-router";

const routerArray = [
  {
    icon: "Calendar",
    index: "1",
    title: "用户管理",
    subs: [
      {
        index: "/item",
        title: "用户列表",
      },
      {
        index: "/item2",
        title: "企业列表",
      },
      {
        index: "/item3",
        title: "图片上传",
      },
    ],
  },

  {
    icon: "Edit",
    index: "2",
    title: "内容管理",
    subs: [
      {
        index: "/articleTable",
        title: "内容列表",
      }

    ],
  },
] as any;

const route = useRoute();
const onRoutes = computed(() => {
  return route.path;
});

const sidebar = useAsideStore();
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 80px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}

.sidebar>ul {
  height: 100%;
}
</style>
