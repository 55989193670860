import { defineStore } from 'pinia'

interface RoutesList {
    name: string;
    path: string;
    title: string;
}
export const useTagsStore = defineStore('tags', {
    state: () => {
        return {
            routesList: <RoutesList[]>[],
        }
    },
    getters: {
        show: state => {
            return state.routesList.length > 0;
        },
        nameList: state => {
            return state.routesList.map(item => item.name);
        }
    },
    actions: {
        // 添加路由标签
        setTagItem(data: RoutesList) {
            this.routesList.push(data)
        },
        // 删除一个路由标签

        delTagItem(index: number) {
            this.routesList.splice(index, 1)
        },
        // 清空
        clearTags() {
            this.routesList = []
        }
    }
})